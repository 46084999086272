import { useGetBlurLoans } from "../../../hooks/use-get-blur-loans";
import DataTable from "react-data-table-component";
import { useEffect, useMemo, useState } from "react";
import { fullDate } from "../../../utils/chartOptions";
import Loader from "../../Loader";
import { FaTelegram } from "react-icons/fa6";

export const BlurLoansWrapper = () => {
  const [showOnAuction, setShowOnAuction] = useState(true);
  const [filteredData, setFilteredData] = useState(null);
  const { blurLoans, loading, lastUpdated } = useGetBlurLoans();
  const sortDates = (rowA, rowB) => {
    const a = rowA.createdAt;
    const b = rowB.createdAt;

    return new Date(a) > new Date(b) ? 1 : -1;
  };

  const columns = useMemo(() => {
    return [
      {
        name: "Picture",
        selector: (row) => row.nftImageUrl,
        format: (row) => (
          <img
            src={row.nftImageUrl}
            alt="Logo"
            className="h-10 w-10 rounded-md"
          />
        ),
        sortable: true,
        minWidth: "40px",
      },
      {
        name: "Collection Name",
        selector: (row) => row.Name,
        sortable: true,
        minWidth: "150px",
      },
      {
        name: "Item number",
        selector: (row) => row.tokenId,
        minWidth: "150px",

        format: (row) => (
          <a
            href={`https://blur.io/eth/asset/${row.contractAddress}/${row.tokenId}`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          > {row.tokenId}</a>
        ),
      },
      {
        name: "Date",
        selector: (row) => fullDate(row.createdAt),
        sortable: true,
        sortFunction: sortDates,
        minWidth: "200px",
      },
      {
        name: "Loan/TVL",
        selector: (row) => row.loanTvl,
        format: (row) => `${row.loanTvl.toFixed(2).toLocaleString()}`,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Interest Rate",
        selector: (row) => row.interestRate,
        format: (row) => `${row.interestRate.toFixed(2).toLocaleString()} %`,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Risk Reward",
        selector: (row) => row.risk_reward,
        format: (row) => `${row.risk_reward.toFixed(2).toLocaleString()}`,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Floor price",
        selector: (row) => row.FloorPrice,
        format: (row) => `${row.FloorPrice.toFixed(2).toLocaleString()}`,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Principal Amount",
        selector: (row) => row.principalAmount,
        format: (row) => `${row.principalAmount.toFixed(2).toLocaleString()}`,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "On Auction",
        selector: (row) => row.on_auction,
        format: (row) => `${row.on_auction ? "Yes" : "No"}`,
        sortable: true,
        minWidth: "140px",
      },
    ];
  }, []);
  useEffect(() => {
    console.log("triggered?");
    if (!blurLoans) return;
    const filtered = blurLoans.filter((item) => {
      return !showOnAuction ? true : item.on_auction === true;
    });
    setFilteredData(filtered);
  }, [blurLoans, showOnAuction]);

  if (loading) {
    return (
      <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="w-full flex h-fit  flex-col px-5 md:px-10 py-10 relative">
      <h2 className="text-[22px] text-neutral-200 font-semibold uppercase">
        Blur Loans
      </h2>
      <p className="text-sm text-neutral-300/70 mb-5 max-w-[80%]">
        Keep track of the blur loans, and get notified when one loan has an
        insane APR!
      </p>
      <a href="https://t.me/blurloanbot" target="_blank" rel="noreferrer">
        <FaTelegram
          className="absolute top-14 right-10 hover:scale-125 cursor-pointer duration-300 transition-all"
          size={30}
        />
      </a>
      {filteredData ? (
        <>
          <div className="w-full flex items-start mb-3">
            <div class="flex items-center justify-center">
              <div className="whitespace-nowrap text-sm font-semibold mr-2">
                Show only auction:
              </div>
              <div class="inline-flex items-center">
                <label class="flex items-center cursor-pointer relative">
                  <input
                    type="checkbox"
                    checked={showOnAuction}
                    onClick={() => {
                      setShowOnAuction(!showOnAuction);
                    }}
                    class="peer h-4 w-4 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-[#d22326] checked:border-[#d22326]"
                    id="check2"
                  />
                  <span class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="w-full border border-neutral-300/20 rounded-md flex flex-col overflow-auto h-fit relative">
            <img
              src="/SVG/pic-nero.svg"
              alt="bc logo"
              className="aspect-square pointer-events-none] h-[70%] w-auto absolute left-1/2 top-1/3 md:top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
            />
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              fixedHeader={true}
              theme="armyTheme"
              paginationPerPage={50}
              paginationRowsPerPageOptions={[50, 100, 250]}
            />
          </div>
          <div className="mt-2 mb-5 text-xs text-red-300/70">
            Last updated: {lastUpdated && fullDate(lastUpdated)}
          </div>
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </main>
  );
};
