import { useState } from "react";
import { TokenKpis } from "../../homepage/TokenKPIS";
import Loader from "../../Loader";

export const TokenOverview = ({data, loading}) => {
  return (
    <div className="w-full px-5 2xl:h-[calc((60vh-160px)/2)] 2xl:min-h-[225px]">
      <div className="oswald text-xl">$ARMY OVERVIEW</div>
      <div
        className="w-full  border border-neutral-300/20  mt-2.5 rounded-sm max-md:max-w-full relative"
      >
        <img
            src="/SVG/pic-nero.svg"
            alt="bc logo"
            className="pointer-events-none h-[50%] md:h-[70%] md:w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
          />
        {!loading && data?.tokenData ? (
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0xdea56d18ae37d64d75bb36a5469bb3753fe886d0?t=1731323612694"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full grid grid-cols-2 gap-y-10  2xl:gap-y-2.5 py-10 2xl:py-2.5">
              <TokenKpis
                value={"$" + data.tokenData.price.toFixed(4)}
                label={"Token Price"}
              />
              <TokenKpis
                value={"$" + data.tokenData.mktcap.toFixed(2)}
                label={"Token Market Cap"}
              />
              <TokenKpis value={data.tokenData.holders} label={"Holders"} />
              <TokenKpis
                value={"$" + data.tokenData.liquidity.toFixed(2)}
                label={"Token Liquidity Pool"}
              />
            </div>
          </a>
        ) : (
          <div className="w-full h-64 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
