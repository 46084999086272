import { Helmet } from "react-helmet";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import PEMainCharts from "../components/containers/PE/PEMainCharts";
import { useEffect, useState } from "react";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
import { useAppKitAccount } from "@reown/appkit/react";

export default function PEAnalysis() {
  const { setPoints } = useSetPoints();
  const [gotPoints, setGotPoints] = useState(false);
  const { address } = useAppKitAccount();

  useEffect(() => {
    if(!address) return
    if(gotPoints) return
    setGotPoints(true)

    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction, address);
    }
    addPoints(points.visit_pe_page).catch(console.error).finally(() => setGotPoints(true));
  }, [address])
  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <Helmet>
        <title>
          Crypto P/E Analysis Dashboard | Real-Time Token Valuations
        </title>
        <meta
          name="description"
          content="Compare cryptocurrency P/E ratios, track token revenues, and discover undervalued projects. Advanced market metrics and historical trends for 100+ tokens."
        />
      </Helmet>
      <AuthenticatedContainer>
        <PEMainCharts />
      </AuthenticatedContainer>
    </main>
  );
}
