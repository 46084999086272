import { useAppKitAccount } from "@reown/appkit/react";
import { useGetPoints } from "../hooks/use-get-points";
import { CgSpinner } from "react-icons/cg";

export const Points = () => {
  const { address, isConnected } = useAppKitAccount();
  const { points, isLoading } = useGetPoints({ address });
  if (!isConnected) return null;
  return (
    <div className="h-[38px] flex items-center gap-x-1 text-xs md:text-sm font-light px-4 border border-neutral-300/20 rounded-full py-1 generic-shadow transition-all duration-200 ease-in">
      <span>ArmyPoints:</span>
      {isLoading ? (
        <CgSpinner className="text-ui-red animate-spin" />
      ) : (
        <span className="font-bold text-ui-red">{points}</span>
      )}
    </div>
  );
};
