import axios from "axios";
import { toast } from "react-toastify";
import { pointsValue } from "../utils/pointsActions";
import { Msg } from "../components/CustomToast";


const isDev =
Boolean(process.env.REACT_APP_IS_DEV_MODE) || false;
export const useSetPoints = () => {
  const setPoints = async (action, address) => {
    if(!action || !address) return

    await axios.post(`https://${isDev ? 'dev' : 'api'}.bc.army/api/add_point`,{
      wallet_id: address,
      action
    })
    .then(function (response) {
      const notify = () => toast(Msg, {points: pointsValue[action],className: 'border border-neutral-300/20',});
      notify()
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  return { setPoints };
};
