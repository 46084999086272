import { Helmet } from "react-helmet";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { BlurLoansWrapper } from "../components/containers/BlurLoans/BlurLoansWrapper";
import { useEffect, useState } from "react";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
import { useAppKitAccount } from "@reown/appkit/react";

export default function BlurLoans() {
  const [gotPoints, setGotPoints] = useState(false);
  const { address } = useAppKitAccount();
  const { setPoints } = useSetPoints();

  useEffect(() => {
    if(!address) return
    if(gotPoints) return
    setGotPoints(true)

    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction, address);
    };
    addPoints(points.blur_loans)
      .catch(console.error)
      .finally(() => setGotPoints(true));
  }, [address]);
  return (
    <main className="w-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <Helmet>
        <title>
          Crypto P/E Analysis Dashboard | Real-Time Token Valuations
        </title>
        <meta
          name="description"
          content="Compare cryptocurrency P/E ratios, track token revenues, and discover undervalued projects. Advanced market metrics and historical trends for 100+ tokens."
        />
      </Helmet>

      <AuthenticatedContainer>
        <BlurLoansWrapper />
      </AuthenticatedContainer>
    </main>
  );
}
