import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { BestFarmsWrapper } from "../components/containers/BestFarms/BestFarmsWrapper";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
import { useAppKitAccount } from "@reown/appkit/react";
export default function BestFarms() {
  const { setPoints } = useSetPoints();
  const [gotPoints, setGotPoints] = useState(false);
  const { address } = useAppKitAccount();

  useEffect(() => {
    if (!address) return;
    if (gotPoints) return;
    setGotPoints(true);

    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction, address);
    };
    addPoints(points.visit_bestfarms)
      .catch(console.error)
      .finally(() => setGotPoints(true));
  }, [address]);
  return (
    <main className="w-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <Helmet>
        <title>Best Yield Farms | DeFi Yield Aggregator & Analytics</title>
        <meta
          name="description"
          content="Find the most profitable yield farming opportunities across multiple chains. Real-time APR tracking, risk assessment, and impermanent loss projections."
        />
      </Helmet>
      <AuthenticatedContainer>
        <BestFarmsWrapper />
      </AuthenticatedContainer>
    </main>
  );
}
