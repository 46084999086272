import { useEffect, useState } from "react";
import BcLogoMobile from "./BCLogoMobile";
import Navigation from "./Navigation";
import { MdClose, MdMenu } from "react-icons/md";

const MobileMenu = () => {
  const [showNavigation, setShowNavigation] = useState(false);

  const openNavigation = () => {
    setShowNavigation(true);
  };
  const closeNavigation = () => {
    setShowNavigation(false);
  };
  useEffect(() => {
    setShowNavigation(false);
  }, []);
  return (
    <div className="md:hidden w-screen h-16  fixed z-50 pointer-events-none">
      <div className="w-full flex flex-row items-center h-16 justify-end pr-3 pointer-events-none">

        <MdMenu
          onClick={openNavigation}
          size={30}
          className=" pointer-events-auto text-neutral-100 cursor-pointer hover:text-white transition"
        />
      </div>
      <div
        className="pointer-events-auto w-screen h-screen fixed top-0 z-50 bg-neutral-800 transition-all duration-300 ease-in-out"
        style={{ right: showNavigation ? "0" : "-100%" }}
      >
        <div className="w-full flex flex-row items-center h-16 justify-between pr-3 border-b border-b-neutral-600">
          <BcLogoMobile />
          <MdClose
            onClick={closeNavigation}
            size={30}
            className="text-neutral-100 cursor-pointer hover:text-white transition"
          />
        </div>
        <Navigation />
      </div>
    </div>
  );
};
export default MobileMenu;
