import { HomeKpi } from "./HeaderKpis";

export const HomeHeader = ({ data }) => {
  const formatToTrillions = (number) => {
    return Number((number / 1_000_000_000_000).toFixed(2));
  };
  const formatToBilions = (number) => {
    return Number((number / 1_000_000_000).toFixed(2));
  };
  const roundVariation = (n) => {
    return n.toFixed(2);
  };
  return (
    <div className="w-full py-3 gap-x-2 gap-y-2 px-2 grid grid-cols-3 md:grid-cols-6 sticky top-16 bg-neutral-900 z-10">
      {data.dominances && (
        <HomeKpi
          label={"Global market cap"}
          value={`$${formatToTrillions(
            data.dominances.quote.USD.total_market_cap
          )}T`}
          variation={roundVariation(
            data.dominances.quote.USD
              .total_market_cap_yesterday_percentage_change
          )}
        />
      )}
      {data.dominances && (
        <HomeKpi
          label={"24h Volume"}
          value={`$${formatToBilions(
            data.dominances.quote.USD.total_volume_24h
          )}B`}
          variation={roundVariation(
            data.dominances.quote.USD
              .total_volume_24h_yesterday_percentage_change
          )}
        />
      )}
      {data.dominances && (
        <HomeKpi
          label={"BTC Dominance"}
          value={`${data.dominances.btc_dominance.toFixed(2)}%`}
          variation={data.dominances.btc_dominance}
          hidePerc={true}
        />
      )}
      {data.dominances && (
        <HomeKpi
          label={"ETH Dominance"}
          value={`${data.dominances.eth_dominance.toFixed(2)}%`}
          variation={data.dominances.eth_dominance}
          hidePerc={true}
        />
      )}
      {data?.feg && data.dominances && (
        <HomeKpi
          label={"Fear & Greed"}
          value={`${data.feg[0].value}/100`}
          variation={data.dominances.eth_dominance}
          hidePerc={true}
          isFEG={true}
          fegToday={data.feg[0].value}
          fegYesterday={data.feg[1].value}
        />
      )}
      {data.dexes && (
        <HomeKpi
          label={"24h DEXs Volume"}
          value={`$${formatToBilions(data.dexes.total24h)}B`}
          variation={data.dexes.change_1d}
        />
      )}
    </div>
  );
};
