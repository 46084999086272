import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { FarmHistoryWrapper } from "../components/containers/FarmHistory/FarmHistoryWrapper";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
import { useAppKitAccount } from "@reown/appkit/react";
export default function BestFarmHistory() {
  const [gotPoints, setGotPoints] = useState(false);
  const { address } = useAppKitAccount();

  const params = useParams();
  const { setPoints } = useSetPoints();

  useEffect(() => {
    if(!address) return
    if(gotPoints) return
    setGotPoints(true)

    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction, address);
    };
    addPoints(points.single_bestfarms).catch(console.error).finally(() => setGotPoints(true));
  }, [address])
  return (
    <main className="w-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <AuthenticatedContainer>
        <FarmHistoryWrapper farmId={params?.farmId} />
      </AuthenticatedContainer>
    </main>
  );
}
