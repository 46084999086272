import { useParams } from "react-router-dom";
import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import { PeTokenWrapper } from "../components/containers/PEToken/PeTokenWrapper";
import { useEffect, useState } from "react";
import { useSetPoints } from "../hooks/use-set-points";
import { points } from "../utils/pointsActions";
import { useAppKitAccount } from "@reown/appkit/react";

export default function PEAnalysisToken() {
    const [gotPoints, setGotPoints] = useState(false);
  
  const params = useParams();
  const { setPoints } = useSetPoints();
  const { address } = useAppKitAccount();

  useEffect(() => {
    if(!address) return
    if(gotPoints) return
    setGotPoints(true)

    const addPoints = async (pointsAction) => {
      await setPoints(pointsAction, address);
    };
    addPoints(points.single_pe_pair).catch(console.error).finally(() => setGotPoints(true));
  }, [address])
  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-20">
      <AuthenticatedContainer>
        <PeTokenWrapper tokenId={params.tokenId} />
      </AuthenticatedContainer>
    </main>
  );
}
